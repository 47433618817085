import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    menuvisible: false,
    itemsmenu: [],
    registros: [],
    cuentas: []
  },
  mutations: {
    cambiarMenuVisible (state, visible) {
      state.menuvisible = visible
    },
    cambiarItemsMenu (state, itemsmenu) {
      state.itemsmenu = itemsmenu
    },
    cargarRegistros (state, registros) {
      state.registros = registros
    },
    cargarCuentas (state, cuentas) {
      state.cuentas = cuentas
    }
  },
  actions: {
  },
  modules: {
  }
})
