<template>
   <v-row no-gutters class="text-center mb-12" wrap align="center">
         <v-col
      cols="12"
      sm="12"
    >
      <v-card
        class="mx-auto"
        outlined
        tile
        elevation="5"
      >
      <v-card-text>
        <v-form
          ref="form"
          class="mb-12"
        >
        <v-row
            justify="center"
            class="mb-2"
          >
          <v-btn v-for="boton in botones" v-bind:key="boton.id"
            :color="boton.color"
            class="mt-5 mr-2"
            @click="clicBoton(boton.accion)"
            style="width: 200px;"
          >
            {{boton.nombre}}
          </v-btn>
          </v-row>
            <v-text-field
            v-model="filtrar"
            append-icon="mdi-magnify"
            label="Filtrar"
            single-line
            hide-details
          ></v-text-field>
          <v-data-table
            :headers="headers"
            :items="$store.state.registros"
            :search="filtrar"
            :mobile-breakpoint='0'
            class="mt-5"
            fixed-header
            :loading="!$store.state.registros.length"
            loading-text="Cargando datos... Por favor, espere..."
          >
          <template v-slot:item="props">
            <tr @click="opcionesRow(props.item, props.index)">
              <td align="left">{{ props.item.ano }}</td>
              <td align="left">{{ props.item.mes }}</td>
              <td align="left">{{ props.item.tipo }}</td>
              <td align="left">$ {{ formatPrice(props.item.monto) }}</td>
              <td align="left">{{ props.item.glosa }}</td>
              <td align="left">{{ props.item.fecha }}</td>
              <td align="left">{{ props.item.cuenta }}</td>
            </tr>
          </template>
          </v-data-table>
        </v-form>
      </v-card-text>
      </v-card>
    </v-col>
    <v-dialog
      v-model="dialog"
      width="350"
      persistent
    >
      <v-card>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            @click="dialog = false"
          >
          X
          </v-btn>
        </v-card-actions>
        <v-card-title class="headline blue--text justify-center" style="word-break: break-word;">
        </v-card-title>
        <v-card-text>
          <v-row
            justify="center"
            align="center"
            class="mb-2"
          >
            <v-btn v-for="opcion in opciones" v-bind:key="opcion.id"
              :color="opcion.color"
              class="mt-5 mr-1"
              @click="clicBoton(opcion.accion)"
              style="width: 250px;"
            >
              {{opcion.nombre}}
            </v-btn>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogDescargarExcel"
      width="350"
      persistent
    >
      <v-card>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            @click="dialogDescargarExcel = false"
          >
          X
          </v-btn>
        </v-card-actions>
        <v-card-text>
          <download-excel
              :data="$store.state.registros"
              name="datosFinanzasIEC.xls"
              class="btn success white--text headline text-center"
            >DESCARGAR EXCEL
          </download-excel>
        </v-card-text>
      </v-card>
    </v-dialog>
    <NuevoEditarRegistro :modo.sync="modoNuevoEditarRegistro" :dialog.sync="dialogNuevoEditarRegistro" :registro.sync="datosNuevoEditarRegistro"/>
    <MoverDinero :dialogMD.sync="dialogMoverDinero"/>
    <Cuentas :dialogCU.sync="dialogCuentas"/>
    <Totales :dialogT.sync="dialogTotales"/>
    <v-overlay :value="loading" :z-index="9999">
      <v-progress-circular
        indeterminate
        :size="100"
        color="primary"
        :width="10"
      ></v-progress-circular>
    </v-overlay>
  </v-row>
</template>

<script>
import Vue from 'vue'
import Globales from '@/mixins/globales.vue'
import NuevoEditarRegistro from '@/components/nuevoeditarregistro.vue'
import MoverDinero from '@/components/moverdinero.vue'
import Cuentas from '@/components/cuentas.vue'
import Totales from '@/components/totales.vue'
import ApiCon from '@/logic/apicon.js'
import JsonExcel from 'vue-json-excel'

Vue.component('downloadExcel', JsonExcel)

export default {
  mixins: [
    Globales
  ],
  components: {
    NuevoEditarRegistro,
    MoverDinero,
    Cuentas,
    Totales
  },
  data: () => ({
    botones: [],
    headers: [],
    filtrar: '',
    idfila: 0,
    opciones: [],
    dialog: false,
    dialogNuevoEditarRegistro: false,
    modoNuevoEditarRegistro: 'NUEVO',
    datosNuevoEditarRegistro: [],
    dialogMoverDinero: false,
    dialogCuentas: false,
    dialogDescargarExcel: false,
    datosExcel: [],
    verboton: false,
    loading: false,
    dialogTotales: false
  }),
  methods: {
    interfaz () {
      this.headers = [
        { text: 'AÑO', align: 'start', value: 'ano' },
        { text: 'MES', align: 'start', value: 'mes' },
        { text: 'TIPO', align: 'start', value: 'tipo' },
        { text: 'MONTO', align: 'start', value: 'monto' },
        { text: 'GLOSA', align: 'start', value: 'glosa' },
        { text: 'FECHA', align: 'start', value: 'fecha' },
        { text: 'CUENTA', align: 'start', value: 'cuenta' }
      ]
      this.botones = [
        { id: 1, nombre: 'NUEVO REGISTRO', color: 'primary', accion: 'nuevoRegistro' },
        { id: 2, nombre: 'TOTALES', color: 'primary', accion: 'mostrarTotales' },
        // { id: 3, nombre: 'INFORME POR FECHA', color: 'primary', accion: 'informeFecha' },
        // { id: 5, nombre: 'HISTORIAL REGISTROS', color: 'primary', accion: 'historialRegistros' },
        { id: 3, nombre: 'MOVER DINERO', color: 'primary', accion: 'moverDinero' },
        { id: 4, nombre: 'CUENTAS', color: 'primary', accion: 'cuentas' },
        { id: 5, nombre: 'EXPORTAR A EXCEL', color: 'primary', accion: 'exportarExcel' }
      ]
      this.opciones = [
        { id: 1, nombre: 'EDITAR REGISTRO', color: 'primary', accion: 'editarRegistro' },
        { id: 2, nombre: 'ELIMINAR REGISTRO', color: 'error', accion: 'eliminarRegistro' }
      ]
    },
    clicBoton (accion) {
      if (accion === 'nuevoRegistro') {
        this.idfila = 0
        this.modoNuevoEditarRegistro = 'NUEVO'
        this.datosNuevoEditarRegistro[0].id = ''
        this.datosNuevoEditarRegistro[0].tipo = ''
        this.datosNuevoEditarRegistro[0].glosa = ''
        this.datosNuevoEditarRegistro[0].fecha = this.fechaHoy()
        this.datosNuevoEditarRegistro[0].monto = ''
        this.datosNuevoEditarRegistro[0].cuenta = ''
        this.dialogNuevoEditarRegistro = true
      } else if (accion === 'moverDinero') {
        this.dialogMoverDinero = true
      } else if (accion === 'cuentas') {
        this.dialogCuentas = true
      } else if (accion === 'editarRegistro') {
        this.modoNuevoEditarRegistro = 'EDITAR'
        this.dialogNuevoEditarRegistro = true
      } else if (accion === 'eliminarRegistro') {
        if (confirm('¿Está seguro que desea eliminar este registro?')) {
          this.eliminarRegistro()
        } else {
          // se deja tal cual
        }
      } else if (accion === 'exportarExcel') {
        this.dialogDescargarExcel = true
      } else if (accion === 'mostrarTotales') {
        this.dialogTotales = true
      }
    },
    opcionesRow (item, index) {
      this.dialog = true
      this.idfila = item.id
      this.datosNuevoEditarRegistro[0].id = item.id
      this.datosNuevoEditarRegistro[0].tipo = item.tipo
      this.datosNuevoEditarRegistro[0].glosa = item.glosa
      this.datosNuevoEditarRegistro[0].fecha = item.fecha
      this.datosNuevoEditarRegistro[0].monto = item.monto
      this.datosNuevoEditarRegistro[0].cuenta = item.cuenta
    },
    async eliminarRegistro () {
      const del = {
        Idregistro: this.datosNuevoEditarRegistro[0].id
      }
      this.loading = true
      const response = await ApiCon.post('registro/eliminar/', del)
      if (response.status === 200) {
        alert('El registro seleccionado ha sido eliminado')
        this.dialog = false
        this.llenaRegistros()
      } else { alert('Ha ocurrido un error...') }
      this.loading = false
    },
    cargarDatos () {
      this.enSesion('sitio')
      this.interfaz()
      this.llenaRegistros()
    }
  },
  created () {
    this.compruebaLogin()
  },
  mounted () {
    this.cargarDatos()
  }
}
</script>

<style scoped>
  .v-data-table.v-data-table.v-data-table >>> td  {
    font-size: 1.0rem !important;
    font-weight:500;
  }
  .v-data-table.v-data-table.v-data-table >>> th  {
    font-size: 1.0rem !important;
    font-weight:500;
    color: #1976d2;
  }
</style>
