<template>
    <v-dialog
      v-model="dialogInput"
      width="450"
      persistent
      :retain-focus="false"
    >
      <v-card>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            @click="dialogInput = false"
          >
          X
          </v-btn>
        </v-card-actions>
        <v-card-title class="headline blue--text justify-center" style="word-break: break-word;">
          {{ titulo }}
        </v-card-title>
        <v-card-text>
          <v-form ref="form" @submit.prevent="guardar" v-model="valid" lazy-validation>
            <v-autocomplete
              label="Seleccionar operación"
              :items="opcionesCuentas"
              v-model="opcionCuenta"
              clearable
              style="margin-bottom: -5px;"
              :rules="requerido"
              required
              @change="seleccionarOpcionCuenta"
            ></v-autocomplete>
            <v-autocomplete
              label="Cuenta de Destino"
              :items="$store.state.cuentas"
              item-text="cuenta"
              v-model="cuentaSelect"
              clearable
              style="margin-bottom: -5px;"
              :rules="requerido"
              required
              v-show="verCuentas"
              no-data-text="Cargando..."
            ></v-autocomplete>
            <v-text-field
              label="Nombre Cuenta"
              v-model="nombreCuenta"
              style="margin-bottom: -5px;"
              :rules="requerido"
              required
              @change="existe"
            ></v-text-field>
            <v-row justify="center">
              <v-btn
                color="primary"
                class="mt-5 mb-5"
                type="submit"
              >
                GUARDAR
              </v-btn>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
      <v-overlay :value="loading" :z-index="9999">
        <v-progress-circular
          indeterminate
          :size="100"
          color="primary"
          :width="10"
        ></v-progress-circular>
      </v-overlay>
    </v-dialog>
</template>

<script>
import Globales from '@/mixins/globales.vue'
import ApiCon from '@/logic/apicon.js'

export default {
  mixins: [
    Globales
  ],
  data: () => ({
    titulo: 'NUEVA/EDITAR CUENTA',
    opcionesCuentas: ['NUEVA', 'EDITAR'],
    opcionCuenta: '',
    cuentaSelect: '',
    verCuentas: false,
    nombreCuenta: '',
    respuesta: '',
    valid: true,
    requerido: [
      v => !!v || 'Complete este campo'
    ],
    loading: false
  }),
  props: ['dialogCU'],
  methods: {
    reset () {
      this.$refs.form.reset()
      this.opcionCuenta = ''
      this.verCuentas = false
      this.nombreCuenta = ''
    },
    guardar () {
      if (this.dataOK() === true) {
        if (this.opcionCuenta === 'NUEVA') {
          this.nueva()
        } else {
          if (this.cuentaSelect !== '' & this.cuentaSelect !== null) {
            this.editar()
          } else {
            alert('Debe seleccionar una cuenta para editarla')
          }
        }
      } else {
        alert('Faltan datos por completar')
      }
    },
    dataOK () {
      if (this.opcionCuenta !== '' & this.opcionCuenta !== null & this.nombreCuenta !== '') {
        return true
      } else {
        this.$refs.form.validate()
        return false
      }
    },
    seleccionarOpcionCuenta () {
      if (this.opcionCuenta === 'EDITAR') {
        this.verCuentas = true
        this.cargarCuentas()
      } else {
        this.verCuentas = false
        this.cuentas = []
      }
    },
    async nueva () {
      const ins = {
        NombreCuenta: this.nombreCuenta
      }
      this.loading = true
      const response = await ApiCon.post('cuenta/insertar', ins)
      if (response.data === 'ok') {
        alert('Cuenta creada :)')
        this.dialogInput = false
      } else { alert('Ha ocurrido un problema...') }
      this.loading = false
    },
    async editar () {
      const edit = {
        TipoCuenta: this.cuentaSelect,
        NombreCuenta: this.nombreCuenta
      }
      this.loading = true
      const response = await ApiCon.post('cuenta/editar', edit)
      if (response.data === 'ok') {
        alert('Nombre de cuenta editado correctamente')
        this.dialogInput = false
      } else { alert('Ha ocurrido un problema...') }
      this.loading = false
    },
    async existe () {
      this.loading = true
      const response = await ApiCon.get1('cuenta/existe', this.nombreCuenta)
      if (response.data === 'si') {
        alert('El nombre de cuenta ingresado ya existe. Ingrese otro.')
        this.nombreCuenta = ''
      }
      this.loading = false
    }
  },
  created () {
    //
  },
  computed: {
    dialogInput: {
      get: function () {
        return this.dialogCU
      },
      set: function (newValue) {
        this.$emit('update:dialogCU', newValue)
      }
    }
  },
  watch: {
    dialogCU () {
      if (this.dialogCU === false) {
        this.reset()
      }
    }
  }
}
</script>
