<script>
// @ is an alias to /src
// Componentes asociados: recuperarPass, registrarPaciente.
import ApiCon from '@/logic/apicon.js'
import Vue from 'vue'
import VueSession from 'vue-session'

Vue.use(VueSession)

export default {
  data: () => ({
    itemsMenu: []
  }),
  methods: {
    enSesion (tipoPagina) {
      if (this.$store.state.menuvisible === false & tipoPagina === 'sitio') {
        this.itemsMenu = [
          // { nombre: 'CENTRAL', icono: 'mdi-menu', goto: 'central' },
          { nombre: 'CERRAR SESIÓN', icono: 'mdi-close-box', goto: 'cerrarsesion' }
        ]
        this.$store.commit('cambiarMenuVisible', true)
        this.$store.commit('cambiarItemsMenu', this.itemsMenu)
      }
    },
    formatDate (date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${day}-${month}-${year}`
    },
    fechaHoy () {
      var date = new Date()
      var day = date.getDate()
      var month = date.getMonth() + 1
      var year = date.getFullYear()

      var resultado = `${day}-${month}-${year}`

      if (day < 10 & month < 10) {
        resultado = `0${day}-0${month}-${year}`
      } else if (month < 10) {
        resultado = `${day}-0${month}-${year}`
      } else if (day < 10) {
        resultado = `0${day}-${month}-${year}`
      }

      return resultado
    },
    async llenaRegistros () {
      const response = await ApiCon.get('registro/todo')
      this.$store.commit('cargarRegistros', response.data)
    },
    async cargarCuentas () {
      const response = await ApiCon.get('cuenta/todas')
      this.$store.commit('cargarCuentas', response.data)
    },
    async compruebaLogin () {
      try {
        const response = await ApiCon.get('auth/comprueba')
        if (response.data === 'ok') {
          // nos quedamos
        }
      } catch (error) {
        if (error.response.status === 401) {
          this.$router.push('/')
        }
      }
    },
    formatPrice (value) {
      const val = (value / 1).toFixed(0).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    }
  }
}
</script>
