<template>
  <v-row no-gutters class="text-center" wrap align="center" style="margin-top: 30px">
    <v-col
    cols="12"
    sm="12"
    >
      <v-card>
          <v-card-title class="headline blue--text justify-center" style="word-break: break-word;">
              NUEVA/EDITAR CREDENCIALES
          </v-card-title>
          <v-card-text>
              <v-form ref="form" @submit.prevent="guardar" v-model="valid" lazy-validation>
              <v-autocomplete
                  label="Seleccionar operación"
                  :items="opcionesCreden"
                  v-model="opcionCreden"
                  clearable
                  style="margin-bottom: -5px;"
                  :rules="requerido"
                  required
                  @change="seleccionarOpcion"
              ></v-autocomplete>
              <v-autocomplete
                  label="Seleccione Credencial"
                  item-text="usuario"
                  :items="usuarios"
                  v-model="usuarioSelect"
                  clearable
                  style="margin-bottom: -5px;"
                  :rules="requerido"
                  required
                  @change="seleccionarCuenta"
                  v-show="verCuentas"
              ></v-autocomplete>
              <v-text-field
                  label="Usuario"
                  v-model="usuario"
                  style="margin-bottom: -5px;"
                  :rules="requerido"
                  required
                  @change="existe"
              ></v-text-field>
              <v-text-field
                v-model="pass"
                label="Contraseña"
                :append-icon="showpass ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showpass ? 'text' : 'password'"
                @click:append="showpass = !showpass"
                required
                :rules="requerido"
              ></v-text-field>
              <v-text-field
                v-model="repass"
                label="Repetir Contraseña"
                :append-icon="showpass ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showpass ? 'text' : 'password'"
                @click:append="showpass = !showpass"
                required
                :rules="requerido"
              ></v-text-field>
              <v-text-field
                  label="Nombre"
                  v-model="nombrePersona"
                  style="margin-bottom: -5px;"
                  :rules="requerido"
                  required
              ></v-text-field>
              <v-text-field
                  label="Apellido Paterno"
                  v-model="apellidoPersona"
                  style="margin-bottom: -5px;"
                  :rules="requerido"
                  required
              ></v-text-field>
              <v-row justify="center">
                  <v-btn
                  color="primary"
                  class="mt-5 mb-12"
                  type="submit"
                  >
                  GUARDAR
                  </v-btn>
              </v-row>
              </v-form>
          </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import Globales from '@/mixins/globales.vue'
import ApiCon from '@/logic/apicon.js'

export default {
  mixins: [
    Globales
  ],
  data: () => ({
    opcionesCreden: ['NUEVA', 'EDITAR'],
    opcionCreden: '',
    usuarios: [],
    usuarioSelect: '',
    verCuentas: false,
    usuario: '',
    pass: '',
    repass: '',
    nombrePersona: '',
    apellidoPersona: '',
    showpass: false,
    valid: true,
    requerido: [
      v => !!v || 'Complete este campo'
    ]
  }),
  methods: {
    reset () {
      this.$refs.form.reset()
      this.opcionCreden = ''
      this.usuarios = []
      this.usuarioSelect = ''
      this.verCuentas = false
      this.usuario = ''
      this.pass = ''
      this.repass = ''
      this.nombrePersona = ''
      this.apellidoPersona = ''
      this.showpass = false
    },
    seleccionarOpcion () {
      if (this.opcionCreden === 'EDITAR') {
        this.cargarUsuarios()
      } else {
        this.verCuentas = false
        this.usuarios = []
        this.usuarioSelect = ''
      }
    },
    seleccionarCuenta () {
      this.cargarCredencial()
    },
    cargarDatos () {
      this.enSesion('sitio')
    },
    guardar () {
      if (this.opcionCreden !== '' & this.opcionCreden !== null) {
        if (this.opcionCreden === 'NUEVA') {
          if (this.dataOKnew() === true) {
            if (this.pass === this.repass) {
              this.nueva()
            } else { alert('La contraseña ingresada en las dos casillas no coincide. Por favor, reintentar.') }
          } else { alert('Faltan datos por completar.') }
        } else {
          if (this.usuarioSelect !== '' & this.usuarioSelect !== null) {
            if (this.dataOKedit() === true) {
              if (this.pass === this.repass) {
                this.editar()
              } else { alert('La contraseña ingresada en las dos casillas no coincide. Por favor, reintentar.') }
            } else { alert('Faltan datos por completar.') }
            this.editar()
          } else { alert('Seleccione un usuario para editar.') }
        }
      } else { alert('Por favor, seleccione una operación.') }
    },
    dataOKnew () {
      if (this.usuario !== '' & this.pass !== '' & this.nombrePersona !== '' &
      this.apellidoPersona !== '' & this.opcionCreden !== '' & this.opcionCreden !== null) {
        return true
      } else {
        this.$refs.form.validate()
        return false
      }
    },
    dataOKedit () {
      if (this.usuarioSelect !== '' & this.usuarioSelect !== null & this.usuario !== '' &
      this.nombrePersona !== '' & this.apellidoPersona !== '' &
      this.opcionCreden !== '' & this.opcionCreden !== null) {
        return true
      } else {
        this.$refs.form.validate()
        return false
      }
    },
    async cargarUsuarios () {
      const response = await ApiCon.get('credencial/listausuarios')
      this.usuarios = response.data
      this.verCuentas = true
    },
    async cargarCredencial () {
      this.reset()
      const response = await ApiCon.get1('credencial/datoscredencial', this.usuarioSelect)
      this.usuario = response.data[0].usuario
      this.nombrePersona = response.data[0].nombre
      this.apellidoPersona = response.data[0].apellido
    },
    async nueva () {
      const ins = {
        Usuario: this.usuario,
        Pass: this.pass,
        NombrePersona: this.nombrePersona,
        AppaternoPersona: this.apellidoPersona
      }
      const response = await ApiCon.post('credencial/insertar', ins)
      if (response.status === 200) {
        alert('Credencial creada :)')
        this.reset()
      } else { alert('Ha ocurrido un problema...') }
    },
    async editar () {
      const edit = {
        UsuarioActual: this.usuarioSelect,
        Usuario: this.usuario,
        Pass: this.pass,
        NombrePersona: this.nombrePersona,
        AppaternoPersona: this.apellidoPersona
      }
      const response = await ApiCon.post('credencial/editar', edit)
      if (response.status === 200) {
        alert('Credencial editada :)')
        this.reset()
      } else { alert('Ha ocurrido un problema...') }
    },
    async existe () {
      const response = await ApiCon.get1('credencial/existe', this.usuario)
      if (response.data === 'si' & this.usuario !== this.usuarioSelect & this.usuarioSelect !== '') {
        alert('El usuario ingresado ya existe. Ingrese otro.')
        this.usuario = ''
      }
    }
  },
  created () {
    this.compruebaLogin()
  },
  mounted () {
    this.cargarDatos()
  }
}
</script>
