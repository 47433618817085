<template>
   <v-row no-gutters class="text-center" wrap align="center" style="margin-top: 30px">
    <v-col
      cols="12"
      sm="12"
    >
    <p class="display-2 primary--text font-weight-regular">FINANZAS IEC</p>
      <v-card
        class="mx-auto"
        outlined
        tile
        elevation="5"
        max-width="720"
        style="padding: 25px;"
      >
      <v-card-text>
        <v-form ref="form" @submit.prevent="login" v-model="valid" lazy-validation>
          <v-text-field
            v-model="usuario"
            label="Usuario"
            required
          ></v-text-field>
          <v-text-field
            v-model="pass"
            label="Contraseña"
            :append-icon="showpass ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showpass ? 'text' : 'password'"
            @click:append="showpass = !showpass"
            required
          ></v-text-field>
          <v-row
            align="center"
            justify="space-around"
          >
          <v-btn
            color="primary"
            class="mt-7"
            type="submit"
          >
            INGRESAR
          </v-btn>
          </v-row>
        </v-form>
      </v-card-text>
      </v-card>
    </v-col>
    <v-overlay :value="loading">
      <v-progress-circular
        indeterminate
        :size="100"
        color="primary"
        :width="10"
      ></v-progress-circular>
    </v-overlay>
  </v-row>
</template>

<script>
import Vue from 'vue'
import Globales from '@/mixins/globales.vue'
import VueSession from 'vue-session'
import ApiCon from '@/logic/apicon.js'
import axios from 'axios'

Vue.use(VueSession)

export default {
  mixins: [
    Globales
  ],
  data: () => ({
    sheet: false,
    valid: false,
    usuario: '',
    pass: '',
    showpass: false,
    itemsMenu: [],
    loading: false
  }),
  methods: {
    cargarDatos () {
      this.$store.commit('cambiarMenuVisible', false)
    },
    async login () {
      if (this.usuario !== '' & this.pass !== '') {
        const cred = {
          Usuario: this.usuario,
          Pass: this.pass
        }
        try {
          this.loading = true
          const response = await ApiCon.post('auth/login', cred)
          if (response.status === 200) {
            axios.defaults.headers.common.Authorization = 'Bearer ' + response.data
            this.$session.set('usuario', this.usuario)
            this.enSesion('sitio')
            this.loading = false
            this.$router.push('central')
          } else {
            this.loading = false
            alert('El usuario y/o contraseña ingresados son incorrectos.')
            this.usuario = ''
            this.pass = ''
          }
        } catch (error) {
          alert('El usuario y/o contraseña ingresados son incorrectos.')
        }
      } else { alert('Por favor, ingrese su usuario y contraseña.') }
      this.loading = false
    }
  },
  created () {
    this.cargarDatos()
  }
}
</script>
