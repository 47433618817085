import axios from 'axios'
const API = 'https://apifinanzas.iglesiaencoquimbo.cl/api/'

export default {
  get (dir) {
    return axios.get(API + dir)
  },
  get1 (dir, var1) {
    return axios.get(API + dir + '/' + var1)
  },
  get2 (dir, var1, var2) {
    return axios.get(API + dir + '/' + var1 + '/' + var2)
  },
  get3 (dir, var1, var2, var3) {
    return axios.get(API + dir + '/' + var1 + '/' + var2 + '/' + var3)
  },
  post (dir, data) {
    return axios.post(API + dir, data)
  }
}
