<template>
    <v-dialog
      v-model="dialogInput"
      width="450"
      persistent
      :retain-focus="false"
    >
      <v-card>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            @click="dialogInput = false"
          >
          X
          </v-btn>
        </v-card-actions>
        <v-card-title class="headline blue--text justify-center" style="word-break: break-word;">
          {{ titulo }}
        </v-card-title>
        <v-card-text>
          <p class="headline primary--text" style="word-break: break-word;">
            INGRESOS: $ {{formatPrice(ingresos)}}
          </p>
          <p class="headline primary--text" style="word-break: break-word;">
            EGRESOS: $ {{formatPrice(egresos)}}
          </p>
          <p class="headline primary--text" style="word-break: break-word;">
            TOTAL: $ {{formatPrice(total)}}
          </p>
          <p class="headline primary--text" style="word-break: break-word;">
            DINERO POR CUENTA
          </p>
          <ul class="primary--text title" style="word-break: break-word;">
            <li v-for="item in cuentas" v-bind:key="item.nombrecuenta">
              {{ item.nombrecuenta }}: $ {{ formatPrice(item.total) }}
            </li>
          </ul>
        </v-card-text>
      </v-card>
      <v-overlay :value="loading" :z-index="9999">
        <v-progress-circular
          indeterminate
          :size="100"
          color="primary"
          :width="10"
        ></v-progress-circular>
      </v-overlay>
    </v-dialog>
</template>

<script>
import Globales from '@/mixins/globales.vue'
import ApiCon from '@/logic/apicon.js'

export default {
  mixins: [
    Globales
  ],
  data: () => ({
    titulo: 'TOTALES',
    origen: '',
    destino: '',
    monto: 0,
    valid: true,
    requerido: [
      v => !!v || 'Complete este campo'
    ],
    loading: false,
    ingresos: '',
    egresos: '',
    total: '',
    cuentas: []
  }),
  props: ['dialogT'],
  methods: {
    reset () {
      this.ingresos = ''
      this.egresos = ''
      this.total = ''
      this.cuentas = []
    },
    async cargarDatos () {
      this.loading = true
      const response = await ApiCon.get('registro/totales')
      if (response.status === 200) {
        this.ingresos = response.data[0].ingresos
        this.egresos = response.data[0].egresos
        this.total = response.data[0].total
        this.cuentas = response.data[0].cuentas
      } else { alert('Ocurrió un error en la operación...') }
      this.loading = false
    }
  },
  computed: {
    dialogInput: {
      get: function () {
        return this.dialogT
      },
      set: function (newValue) {
        this.$emit('update:dialogT', newValue)
      }
    }
  },
  watch: {
    dialogT () {
      if (this.dialogT === false) {
        this.reset()
      } else {
        this.cargarDatos()
      }
    }
  }
}
</script>
