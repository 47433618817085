<template>
    <v-dialog
      v-model="dialogInput"
      width="450"
      persistent
      :retain-focus="false"
    >
      <v-card>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            @click="dialogInput = false"
          >
          X
          </v-btn>
        </v-card-actions>
        <v-card-title class="headline blue--text justify-center" style="word-break: break-word;">
          {{ modoInput }} {{ titulo }}
        </v-card-title>
        <v-card-text>
          <v-form ref="form" @submit.prevent="guardarRegistro" v-model="valid" lazy-validation>
            <v-autocomplete
              label="Tipo de Registro"
              :items="tipos"
              v-model="registroInput[0].tipo"
              clearable
              style="margin-bottom: -5px;"
              :rules="requerido"
              required
            ></v-autocomplete>
            <v-menu
              ref="menu1"
              v-model="menu1"
              :close-on-content-click="true"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="registroInput[0].fecha"
                  label="Fecha"
                  persistent-hint
                  v-bind="attrs"
                  v-on="on"
                  readonly
                  style="margin-bottom: -5px;"
                  :rules="requerido"
                  required
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="date"
                no-title
                locale="es-cl"
                :first-day-of-week="1"
                @input="menu1 = false"
                show-current="false"
              ></v-date-picker>
            </v-menu>
            <v-text-field
              label="Glosa"
              v-model="registroInput[0].glosa"
              style="margin-bottom: -5px;"
              :rules="requerido"
              required
            ></v-text-field>
            <v-text-field
              label="Monto ($)"
              v-model="registroInput[0].monto"
              style="margin-bottom: -5px;"
              :rules="requerido"
              required
              type="number"
            ></v-text-field>
            <v-autocomplete
              label="Cuenta"
              :items="$store.state.cuentas"
              v-model="registroInput[0].cuenta"
              clearable
              style="margin-bottom: -5px;"
              :rules="requerido"
              required
              no-data-text="Cargando..."
            ></v-autocomplete>
            <v-row justify="center">
              <v-btn
                color="primary"
                class="mt-5 mb-5"
                type="submit"
              >
                REGISTRAR
              </v-btn>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
      <v-overlay :value="loading" :z-index="9999">
        <v-progress-circular
          indeterminate
          :size="100"
          color="primary"
          :width="10"
        ></v-progress-circular>
      </v-overlay>
    </v-dialog>
</template>

<script>
import Globales from '@/mixins/globales.vue'
import ApiCon from '@/logic/apicon.js'

export default {
  mixins: [
    Globales
  ],
  data: () => ({
    titulo: 'REGISTRO',
    tipos: ['INGRESO', 'EGRESO'],
    date: new Date().toISOString().substr(0, 10),
    menu1: false,
    valid: true,
    requerido: [
      v => !!v || 'Complete este campo'
    ],
    loading: false
  }),
  props: ['modo', 'dialog', 'registro'],
  methods: {
    reset () {
      this.$refs.form.reset()
      this.registro[0].id = ''
      this.registro[0].tipo = ''
      this.registro[0].glosa = ''
      this.registro[0].monto = ''
      this.registro[0].cuenta = ''
      this.date = new Date().toISOString().substr(0, 10)
    },
    cargarDatos () {
      this.registro.push({ id: '', tipo: '', glosa: '', fecha: '', monto: '', cuenta: '' })
    },
    guardarRegistro () {
      if (this.dataOK() === true) {
        if (this.modoInput === 'NUEVO') {
          this.guardar()
        } else {
          this.editar()
        }
      } else { alert('Faltan datos por completar') }
    },
    dataOK () {
      if (this.registro[0].tipo !== '' & this.registro[0].glosa !== '' & this.registro[0].fecha !== '' &
      this.registro[0].monto !== '' & this.registro[0].cuenta !== '' & this.registro[0].tipo !== null &
      this.registro[0].cuenta !== null) {
        return true
      } else {
        this.$refs.form.validate()
        return false
      }
    },
    async guardar () {
      const ins = {
        Credencial: this.$session.get('usuario'),
        Cuenta: this.registro[0].cuenta,
        TipoRegistro: this.registro[0].tipo,
        GlosaRegistro: this.registro[0].glosa,
        FechaRegistro: this.registro[0].fecha,
        MontoRegistro: this.registro[0].monto
      }
      this.loading = true
      const response = await ApiCon.post('registro/insertar', ins)
      if (response.status === 200) {
        alert('Registro guardado :)')
        this.llenaRegistros()
        this.dialogInput = false
      } else { alert('Ha ocurrido un error con el guardado de datos') }
      this.loading = false
    },
    async editar () {
      const edit = {
        Idregistro: this.registro[0].id,
        Credencial: this.$session.get('usuario'),
        Cuenta: this.registro[0].cuenta,
        TipoRegistro: this.registro[0].tipo,
        GlosaRegistro: this.registro[0].glosa,
        FechaRegistro: this.registro[0].fecha,
        MontoRegistro: this.registro[0].monto
      }
      this.loading = true
      const response = await ApiCon.post('registro/editar', edit)
      if (response.status === 200) {
        alert('Registro editado :)')
        this.llenaRegistros()
        this.dialogInput = false
      } else { alert('Ha ocurrido un error con la edición de datos') }
      this.loading = false
    }
  },
  created () {
    this.cargarDatos()
  },
  computed: {
    dialogInput: {
      get: function () {
        return this.dialog
      },
      set: function (newValue) {
        this.$emit('update:dialog', newValue)
      }
    },
    modoInput: {
      get: function () {
        return this.modo
      },
      set: function (newValue) {
        this.$emit('update:modo', newValue)
      }
    },
    registroInput: {
      get: function () {
        return this.registro
      },
      set: function (newValue) {
        this.$emit('update:registro', newValue)
      }
    }
  },
  watch: {
    dialog () {
      if (this.dialog === false) {
        this.reset()
      } else {
        this.cargarCuentas()
      }
    },
    date () {
      this.registro[0].fecha = this.formatDate(this.date)
    }
  }
}
</script>
