import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/index.vue'
import Central from '../views/central.vue'
import Credenciales from '../views/credenciales.vue'
import CerrarSesion from '../views/cerrarsesion.vue'

Vue.use(VueRouter)

const routes = [
  { path: '/', name: 'index', component: Index },
  { path: '/central', name: 'central', component: Central },
  { path: '/credenciales', name: 'credenciales', component: Credenciales },
  { path: '/cerrarsesion', name: 'cerrarsesion', component: CerrarSesion }
]

const router = new VueRouter({
  routes
})

export default router
