<template>
    <v-dialog
      v-model="dialogInput"
      width="450"
      persistent
      :retain-focus="false"
    >
      <v-card>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            @click="dialogInput = false"
          >
          X
          </v-btn>
        </v-card-actions>
        <v-card-title class="headline blue--text justify-center" style="word-break: break-word;">
          {{ titulo }}
        </v-card-title>
        <v-card-text>
          <v-form ref="form" @submit.prevent="guardar" v-model="valid" lazy-validation>
            <v-autocomplete
              label="Cuenta de Origen"
              :items="$store.state.cuentas"
              item-text="cuenta"
              v-model="origen"
              clearable
              style="margin-bottom: -5px;"
              :rules="requerido"
              required
              no-data-text="Cargando..."
            ></v-autocomplete>
            <v-autocomplete
              label="Cuenta de Destino"
              :items="$store.state.cuentas"
              item-text="cuenta"
              v-model="destino"
              clearable
              style="margin-bottom: -5px;"
              :rules="requerido"
              required
              no-data-text="Cargando..."
            ></v-autocomplete>
            <v-text-field
              label="Monto ($)"
              v-model="monto"
              style="margin-bottom: -5px;"
              :rules="requerido"
              required
              type="number"
            ></v-text-field>
            <v-row justify="center">
              <v-btn
                color="primary"
                class="mt-5 mb-5"
                type="submit"
              >
                MOVER
              </v-btn>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
      <v-overlay :value="loading" :z-index="9999">
        <v-progress-circular
          indeterminate
          :size="100"
          color="primary"
          :width="10"
        ></v-progress-circular>
      </v-overlay>
    </v-dialog>
</template>

<script>
import Globales from '@/mixins/globales.vue'
import ApiCon from '@/logic/apicon.js'

export default {
  mixins: [
    Globales
  ],
  data: () => ({
    titulo: 'MOVER DINERO',
    origen: '',
    destino: '',
    monto: '',
    valid: true,
    requerido: [
      v => !!v || 'Complete este campo'
    ],
    loading: false
  }),
  props: ['dialogMD'],
  methods: {
    reset () {
      this.$refs.form.reset()
      this.origen = ''
      this.destino = ''
      this.monto = ''
    },
    cargarDatos () {
      //
    },
    guardar () {
      if (this.dataOK() === true) {
        if (this.origen !== this.destino) {
          this.mover()
        } else (alert('La cuenta de origen no puede ser la misma que la de destino'))
      } else { alert('Faltan datos por completar') }
    },
    dataOK () {
      if (this.origen !== '' & this.origen !== null & this.destino !== '' &
      this.destino !== null & this.monto !== '') {
        return true
      } else {
        this.$refs.form.validate()
        return false
      }
    },
    async mover () {
      const mov = {
        Origen: this.origen,
        Destino: this.destino,
        Monto: this.monto,
        Usuario: this.$session.get('usuario')
      }
      this.loading = true
      const response = await ApiCon.post('registro/moverdinero', mov)
      if (response.status === 200) {
        alert('Operación exitosa :)')
        this.llenaRegistros()
        this.dialogInput = false
      } else { alert('Ocurrió un error en la operación...') }
      this.loading = false
    }
  },
  created () {
    //
  },
  computed: {
    dialogInput: {
      get: function () {
        return this.dialogMD
      },
      set: function (newValue) {
        this.$emit('update:dialogMD', newValue)
      }
    },
    datosInput: {
      get: function () {
        return this.datos
      },
      set: function (newValue) {
        this.$emit('update:datos', newValue)
      }
    }
  },
  watch: {
    dialogMD () {
      if (this.dialogMD === false) {
        this.reset()
      } else {
        this.cargarCuentas()
      }
    }
  }
}
</script>
